<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="anasalealert">
        <div class="kanban_box">
            <table class="kanban_guize_table">
                <tr>
                    <th>项目</th>
                    <th>预警规则</th>
                </tr>
                <tr>
                    <td>访客记录日志</td>
                    <td>≤<input type="number" v-model="fangke_jilu_rzhi">次</td>
                </tr>
                <tr>
                    <td>漏斗阶段</td>
                    <td>≤<input type="number" v-model="loudou_jieduan">天</td>
                </tr>
                <tr>
                    <td>合同</td>
                    <td>≤<input type="number" v-model="hetong">天</td>
                </tr>
                <tr>
                    <td>发票</td>
                    <td>≤<input type="number" v-model="fapiao">天</td>
                </tr>
                <tr>
                    <td>未开发票</td>
                    <td>≤<input type="number" v-model="weikai_fapiao">天</td>
                </tr>
                <tr>
                    <td>回款</td>
                    <td>≤<input type="number" v-model="huikuan">天</td>
                </tr>
                <tr>
                    <td>应收金额</td>
                    <td>≤<input type="number" v-model="yingshou_jine">天</td>
                </tr>
                <tr>
                    <td>交付</td>
                    <td>≤<input type="number" v-model="jiaofu">天</td>
                </tr>
            </table>
            <p class="kanban_guize_baocun">
                <span @click="dianji_guize_baocun">保存</span>
            </p>
        </div>
    </div>
</template>

<script>
import { query_sale_mng_warn_rule, insert_sale_mng_warn_rule } from '../../api/api.js'
export default {
  name: 'anasalealert',
  data () {
    return {
      fangke_jilu_rzhi: '7',
      loudou_jieduan: '60',
      hetong: '14',
      fapiao: '45',
      weikai_fapiao: '45',
      huikuan: '60',
      yingshou_jine: '60',
      jiaofu: '15'
    }
  },
  mounted () {
  },
  created () {
    this.jichu()
  },
  watch: {
        
  },
  methods: {
    jichu () {
      query_sale_mng_warn_rule({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          date.map((item, index) => {
            if (item.item == 'visit') {
              this.fangke_jilu_rzhi = item.num
            } else if (item.item == 'funn') {
              this.loudou_jieduan = item.num
            } else if (item.item == 'con') {
              this.hetong = item.num
            } else if (item.item == 'inv') {
              this.fapiao = item.num
            } else if (item.item == 'inv_no') {
              this.weikai_fapiao = item.num
            } else if (item.item == 'rec') {
              this.huikuan = item.num
            } else if (item.item == 'rec_no') {
              this.yingshou_jine = item.num
            } else if (item.item == 'deliver') {
              this.jiaofu = item.num
            }
          })
        } else if (res.data.code == 500) {}
      })
    },
    dianji_guize_baocun () {
      if (this.fangke_jilu_rzhi.length != 0 && this.loudou_jieduan.length != 0 && this.hetong.length != 0 && this.fapiao.length != 0 && this.weikai_fapiao.length != 0 && this.huikuan.length != 0 && this.yingshou_jine.length != 0 && this.jiaofu.length != 0) {
        insert_sale_mng_warn_rule({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            list: [
              {
                item: 'visit',
                active: '1',
                num: this.fangke_jilu_rzhi
              },
              {
                item: 'funn',
                active: '1',
                num: this.loudou_jieduan
              },
              {
                item: 'con',
                active: '1',
                num: this.hetong
              },
              {
                item: 'inv',
                active: '1',
                num: this.fapiao
              },
              {
                item: 'inv_no',
                active: '1',
                num: this.weikai_fapiao
              },
              {
                item: 'rec',
                active: '1',
                num: this.huikuan
              },
              {
                item: 'rec_no',
                active: '1',
                num: this.yingshou_jine
              },
              {
                item: 'deliver',
                active: '1',
                num: this.jiaofu
              }
            ]
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
          } else if (res.data.code == 500) {}
        })
      } else {
        this.$message({
          message: '没有填写完信息',
          type: 'warning'
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import './quanxian.scss';
</style>
